<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: letter portrait;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
    }

    .page-break {
        page-break-before: always;
        counter-reset: page;
    }
}

#logo {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 200px;
    height: 100px;
}

.title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

th.no-border {
    border: none;
}

.remove_border tr th {
    border: none;
}

#table-list {
    margin-top: 80px;
}

.info {
    margin-top: 20px;
    width: 40%;
    float: left;
    margin-bottom: 20px;
}

.space-for-logo {
    width: 200px;
    height: 100px;
}

#page-counter {
    display: table-footer-group;
}

#page-counter:after {
    counter-increment: page;
    content: " " counter(page) " of " counter(page);
}

.signature-table {
    page-break-inside: avoid;
    margin-top: 20px;
}
</style>

<template>
    <div>
        <div class="page-break">
            <table class="" style="width: 100%">
                <thead>
                    <tr class="">
                        <th colspan="7">
                            <div class="title">
                                <p>PICKING LIST - INTER WAREHOUSE</p>
                                <p>{{ this.company.Name }}</p>
                            </div>
                        </th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Document</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ stoCode }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print</th>
                        <th class="py-0 w-1" colspan="2">: {{ printCounter }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Handover Doc.</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ handoverCode }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print date</th>
                        <th class="py-0 w-1" colspan="2">: {{ dateNow }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Source Warehouse</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ srcWarehouse.code }} - {{ srcWarehouse.name }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Print time</th>
                        <th class="py-0 w-1" colspan="2">: {{ timeNow }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Dest. Warehouse</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ destWarehouse.code }} - {{ destWarehouse.name }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Driver</th>
                        <th class="py-0 w-1" colspan="2">: {{ selectedDriver.name }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">Req. Delivery Date</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ formatDate(reqDate) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5">Vehicle</th>
                        <th class="py-0 w-1" colspan="2">: {{ selectedVehicle.Number }}</th>
                    </tr>
                    <tr style="white-space: nowrap">
                        <th class="py-0 w-32">ETA</th>
                        <th class="py-0 w-1">:</th>
                        <th class="py-0 w-32">{{ formatDate(eta) }}</th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1" colspan="2"></th>
                    </tr>
                    <tr>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-1"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-32"></th>
                        <th class="py-0 w-5"></th>
                        <th class="py-0 w-1"></th>
                        <th class="py-0 w-5"></th>
                    </tr>
                </thead>
            </table>
            <br>
            <table class="" style="width: 100%;">
                <thead>
                    <tr style="border-top: 1px dashed; border-bottom: 1px dashed">
                        <th style="vertical-align: middle; text-align: left">Sku Code</th>
                        <th style="vertical-align: middle; text-align: left">Sku Description</th>
                        <th style="vertical-align: middle; text-align: left">Batch</th>
                        <th style="vertical-align: middle; text-align: left">Exp. Date</th>
                        <th style="vertical-align: middle; text-align: left">Quantity</th>
                        <th style="vertical-align: middle; text-align: left">Unit</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(tr, index) in line_convertions">
                        <template v-for="(bg, b_index) in tr.batch_groups">
                            <template v-for="(ug, u_index) in bg.unit_groups">
                                <tr :key="`batch-${index}-${b_index}-${u_index}`">
                                    <td class="py-1" style="padding-left: 10px;">{{ tr.sku_code }}</td>
                                    <td class="py-1" style="padding-left: 10px;">{{ tr.item_name }}</td>
                                    <td class="py-1" style="padding-left: 10px;">{{ bg.batch }}</td>
                                    <td class="py-1" style="padding-left: 10px;">{{ bg.exp_date }}</td>
                                    <td class="py-1" style="padding-left: 10px;">{{ ug.quantity }}</td>
                                    <td class="py-1" style="padding-left: 10px;">{{ ug.unit }}</td>
                                </tr>
                            </template>
                        </template>

                        <template v-for="(tq, t_index) in tr.total_quantities">
                            <tr :key="`total-${index}-${t_index}`">
                                <td v-show="t_index == 0" colspan="3" :rowspan="tr.total_quantities.length"></td>
                                <td v-show="t_index == 0" class="py-1" :rowspan="tr.total_quantities.length" :style="t_index == 0 ? 'text-align: center; border-top: 1px dashed' : 'text-align: center;'">Total</td>
                                <td class="py-1" :style="t_index == 0 ? 'border-top: 1px dashed' : ''" style="padding-left: 10px;">{{ tq.quantity }}</td>
                                <td class="py-1" :style="t_index == 0 ? 'border-top: 1px dashed' : ''" style="padding-left: 10px;">{{ tq.unit }}</td>
                            </tr>
                        </template>

                        <tr :key="index">
                            <td colspan="6" style="height: 20px;"></td>
                        </tr>
                    </template>
                </tbody>
                <tfoot>
                    <tr style="border-top: 1px dashed">
                        <td colspan="6"></td>
                    </tr>
                </tfoot>
            </table>
            <br>
            <table class="remove_border signature-table" style="page-break-before: auto">
                <tr>
                    <th style="text-align: center">Dibuat oleh</th>
                    <th style="text-align: center">Disiapkan oleh</th>
                    <th style="text-align: center">Diserahkan oleh</th>
                    <th style="text-align: center">Diterima oleh</th>
                </tr>
                <tr>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                    <th style="height: 50px"></th>
                </tr>
                <tr>
                    <th style="text-align: center">Admin Logistic</th>
                    <th style="text-align: center">Picker</th>
                    <th style="text-align: center">Checker</th>
                    <th style="text-align: center">Driver</th>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import logoSDN from "../../../assets/images/logo/logo-sdn.png";
// import { debounce } from 'lodash';

export default {
    name: "PrintPickingList",
    data() {
        return {
            id: null,
            readonly: true,
            stoCode: null,
            handoverCode: null,
            handoverDate: null,
            reqDate: null,
            eta: null,
            line_convertions: [
                {
                    sku_code: null,
                    item_name: null,
                    batch_groups: [
                        {
                            batch: null,
                            exp_date: null,
                            unit_groups: [
                                {
                                    quantity: 0,
                                    unit: null,
                                }
                            ]
                        }
                    ],
                    total_quantities: [
                        {
                            quantity: 0,
                            unit: null,
                        }
                    ]
                }
            ],
            checked: [],
            selectedVehicle: null,
            selectedDriver: null,
            logoSDN: logoSDN,
            showInfo: false,
            company: null,
            srcWarehouse: null,
            destWarehouse: null,
            printCounter: 0,
            dateNow: "",
            timeNow: "",
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.print();
        }
    },
    methods: {
        async print() {
            await this.getData()
            await window.print();
        },
        async getData() {
            this.$vs.loading();
            let resp = await this.$http.get(
                "/api/wms/v1/outbound/sto-processing/print-picking-list/" + this.id
            );
            if (resp.code == 200) {
                this.stoCode = resp.data.sto_code;
                this.handoverCode = resp.data.handover_code;
                this.company = resp.data.company;
                this.srcWarehouse = resp.data.src_warehouse;
                this.destWarehouse = resp.data.dest_warehouse;
                this.selectedVehicle = resp.data.vehicle;
                this.selectedDriver = resp.data.driver;
                this.handoverDate = moment(resp.data.handover_date).toDate();
                this.reqDate = resp.data.req_date;
                this.eta = resp.data.eta;
                this.printCounter = resp.data.print_counter;
                this.line_convertions = resp.data.line_convertions;

                const now = new Date();
                this.dateNow = this.formatDate(now.setHours(now.getHours() + 7));
                this.timeNow = this.formatTime(now);
                this.$vs.loading.close();
            } else {
                this.$vs.loading.close();
            }
        },
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("YYYY-MM-DD");
            };
        },
        formatTime: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format("hh:mm:ss");
            };
        },
    },
}
</script>
